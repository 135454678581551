import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Newsletter from "../components/Newsletter"
import { buildTitle, PageTitle } from "../components/PageTitle"

const Home = () => {
  return (
    <div>
      <PageTitle title={buildTitle("Contacte")} />
      <Navbar />

      {/* Título de la página */}
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-6xl lg:text-8xl tracking-wide font-bold text-blue font-brik px-6'>Contacte</h1>
      </div>

      {/* Sección Entitat */}
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-6 text-blue'>
        <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-6'>Entitat</h2>
        <div className='flex flex-col lg:flex-row justify-start'>
          <section className='pb-8 lg:pr-32'>
            <h3 className='text-xl font-bold text-blue uppercase'>Informació</h3>
            <p className='font-mori'>secretaria@joventutdelafarandula.cat</p>
            <p className='font-mori'>93 725 23 94</p>
          </section>
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Adreça</h3>
            <p className='font-mori'>
              Joventut de la Faràndula <br />
              Carrer de la república, 47 <br />
              08202 Sabadell, Barcelona
            </p>
          </section>
        </div>
      </div>

      {/* Sección Departaments */}
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-6 text-blue'>
        <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-6'>Departaments</h2>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 gap-y-12'>
          {/* Presidència */}
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Presidència</h3>
            <p className='font-mori'>Anna Vidal</p>
            <p className='font-mori'>entitat@joventutdelafarandula.cat</p>
          </section>

          {/* Formació */}
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Formació</h3>
            <p className='font-mori'>Maria Closas</p>
            <p className='font-mori'>formacio@joventutdelafarandula.cat</p>
          </section>

          {/* Màrqueting i Comunicació */}
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Màrqueting i Comunicació</h3>
            <p className='font-mori'>Anna Vidal</p>
            <p className='font-mori'>entitat@joventutdelafarandula.cat</p>
          </section>

          {/* Econòmic, Producció i Logística */}
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Econòmic, Producció i Logística</h3>
            <p className='font-mori'>Maria Closas</p>
            <p className='font-mori'>formacio@joventutdelafarandula.cat</p>
          </section>

          {/* Teatral */}
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Teatral</h3>
            <p className='font-mori'>Ferran Vidal</p>
            <p className='font-mori'>legal@joventutdelafarandula.cat</p>
          </section>

          {/* Social */}
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Social</h3>
            <p className='font-mori'>Montse Paredes</p>
            <p className='font-mori'>social@joventutdelafarandula.cat</p>
          </section>

          {/* Jurídic i Secretariat */}
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Jurídic i Secretariat</h3>
            <p className='font-mori'>Pol Moix</p>
            <p className='font-mori'>economia@joventutdelafarandula.cat</p>
          </section>

          {/* Taller de Teatre Infantil */}
          <section>
            <h3 className='text-xl font-bold text-blue uppercase'>Taller de Teatre Infantil</h3>
            <p className='font-mori'>Equip de Monitores i Monitors</p>
            <p className='font-mori'>tti@joventutdelafarandula.cat</p>
          </section>
        </div>
      </div>

      <Newsletter />
      <Footer />
    </div>
  )
}

export default Home
