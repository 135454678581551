import React, { useEffect, useState } from "react"

const CardSmall = () => {
  const [apiData, setApiData] = useState(null)

  useEffect(() => {
    fetch("http://localhost:8080/api/home")
      .then((response) => response.json())
      .then((data) => setApiData(data))
      .catch((error) => console.error("Error fetching data:", error))
  }, [])

  console.log(apiData)

  const cardData = {
    backgroundColor: "#3C158226",
    title: "ALADÍ",
    text1: "28, 29 d'October",
    text2: "04, 05, 11, 12 de November",
    buttonText: "COMPRA D'ENTRADES"
  }

  const Card = ({ title, text1, text2, buttonText }) => {
    return (
      <div className={`bg-amber-50 p-4 m-4 w-full mt-5 overflow-hidden rounded-2xl shadow-lg`}>
        <div className='p-4'>
          <h2 className='mb-2 text-3xl font-bold text-blue font-brik'>{title}</h2>
          <p className=' text-lg text-blue  font-mori'>{text1}</p>
          <p className=' text-lg  text-blue  font-mori mb-4'>{text2}</p>
          <button className='w-full h-2/3 bg-blue text-gray text-m py-1 px-2 rounded-full'>{buttonText}</button>
        </div>
      </div>
    )
  }

  return <Card {...cardData} />
}

export default CardSmall
