import React from "react"
import "./App.css"
import Home from "./pages/Home"
import Contacte from "./pages/Contacte"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Entrades from "./pages/Entrades"
import ComArribar from "./pages/ComArribar"
import Historia from "./pages/Historia"
import Tti from "./pages/Tti"
import ProjectDetail from "./pages/ProjectDetail"
import QuiSom from "./pages/QuiSom"
import Tta from "./pages/Tta"
import AulaJove from "./pages/AulaJove"
import TenmporadesAnteriors from "./pages/TemporadesAnteriors"
import ApostesEnEscena from "./pages/ApostesEnEscena"
import Notfound from "./pages/NotFound"

function App() {
  return (
    <BrowserRouter>
      <main className='bg-gray min-h-screen h-auto p-6'>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/Contacte' element={<Contacte />} />
          <Route path='/Programacio' element={<Home />} />
          <Route path='/Entrades' element={<Entrades />} />
          <Route path='/ComArribar' element={<ComArribar />} />
          <Route path='/Historia' element={<Historia />} />
          <Route path='/qui-som' element={<QuiSom />} />
          <Route path='/Tti' element={<Tti />} />
          <Route path='/Tta' element={<Tta />} />
          <Route path='/AulaJove' element={<AulaJove />} />
          <Route path='/Temporades-anteriors' element={<TenmporadesAnteriors />} />
          <Route path='/apostes-en-escena' element={<ApostesEnEscena />} />
          <Route path='/espectacle/:slug' element={<ProjectDetail />} />
          <Route path='*' element={<Notfound />} />

        </Routes>
      </main>
    </BrowserRouter>
  )
}

export default App
