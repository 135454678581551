import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { buildTitle, PageTitle } from "../components/PageTitle"

const Historia = () => {
  return (
    <div>
      <PageTitle title={buildTitle("Historia")} />
      <Navbar />
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-6xl lg:text-8xl tracking-wide font-bold text-blue font-brik px-6 uppercase'>Historia</h1>
      </div>
      <div className='bg-gray border-r border-l border-blue py-4 px-0 text-blue px-6'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Primers Passos</h2>
            <p className='font-mori'>
              Durant la primavera de l'any 1947, un petit grup de joves del Quadre Escènic de la Congregació Mariana de Sabadell, marcat per una clara vocació
              de fer teatre per a infants, comentava les dificultats que els impedien avançar en la seva tasca. El problema principal era la limitació d'haver
              de representar obres amb repartiment únicament masculí, degut a les normes de l'entitat i a l'escrupolositat moral que les recolzava.
            </p>
            <p className='font-mori'>
              Així doncs, animats pel sacerdot conciliari de la Congregació, el pare Josep Maria Nolla, i secundats per alguns Congregants d'edats més
              avançades, van començar a acaronar l'esperança de poder fundar un grup teatral mixt i independent, encara que fos sota la tutela de l'entitat
              mare, la qual havia fundat el seu quadre escènic l'any 1939.
            </p>
            <p className='font-mori'>
              Finalment, els dos col·lectius es van posar d'acord i, ajuntant experiències i persones, van fundar la JOVENTUT DE LA FARÀNDULA, com a entitat
              mixta especialitzada en espectacles escènics infantils. Aquest fet es va oficialitzar el 18 de setembre de 1947.
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>S'Aixeca el Teló</h2>
            <p className='font-mori'>
              El 23 de novembre del mateix any, la Joventut de la Faràndula aixecava el teló per primera vegada al "Teatro Alcázar", del carrer Les Planes de
              Sabadell, i duia a terme la seva primera temporada teatral, la 1947/1948, amb un total de 14 representacions, 13 de tarda i una de nit per a
              adults, i 8 títols diferents. Ja es feu el primer cicle d'Els Pastorets, amb 7 representacions, i s'estrenaren altres obres com ara La Ventafocs,
              Les aventures d'en Massagran, Nit de Reis i El divino impaciente.
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Cessió del Teatre</h2>
            <p className='font-mori'>
              El març de 1972, el teatre va passar a mans de la ciutat de Sabadell, ateses les dificultats de l'Entitat per a mantenir-lo i explotar-lo
              degudament. En compensació de les condicions tan avantatjoses per a l'Ajuntament de Sabadell, el mateix Consistori va cedir a la Joventut de la
              Faràndula, per a temps indefinit, els espais que ocupava l'Entitat com a estatge social, pactant condicions especials per a l'ús del teatre per
              part de la Joventut de la Faràndula i també la continuïtat de les programacions infantils, els cicles d'Els Pastorets i la representació per a
              adults de Nadal a la nit.
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Teatre Propi</h2>
            <p className='font-mori'>
              L'altre gran problema que calia resoldre era la manca de local propi amb capacitat suficient, de manera que, l'any 1955, va obrir-se una
              subscripció popular d'accions per a la compra d'un terreny i per a l'edificació d'un teatre. A principis de 1956, es va constituir una societat
              mercantil que es va fer càrrec de l'operació. Després es va comprar el terreny situat entre els carrers Alfons XIII i Tres Creus de Sabadell. El
              15 d'abril de 1955, va començar la construcció del Teatre "La Faràndula", que fou inaugurat el 30 de desembre de 1956.
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Fins a l'Actualitat</h2>
            <p className='font-mori'>
              Durant els més de setanta cinc anys de vida ininterrompuda de l'Entitat, s'han mantingut les temporades de programació infantil a Sabadell, s'han
              fet actuacions per tot Catalunya i s'ha participat en un gran nombre de concursos que han aportat molts guardons i premis a la Joventut de la
              Faràndula, amb un nombre total d'actuacions que ja ratlla les tres mil.
            </p>
            <p className='font-mori'>
              Paral·lelament, l'Entitat ha organitzat molts cursos de formació teatral per als seus membres, el quals es mantenen de forma estable des de l'any
              1979, en què es va fundar el "Taller de Teatre Infantil", complementat des de l'any 1993 amb l'"Aula Jove de Teatre" i amb el "Taller de Teatre
              per Adults (TTA)" per a adults.
            </p>
            <p className='font-mori'>
              En el marc de la celebració del 75è aniversari de l'Entitat, la Generalitat de Catalunya va concedir la Creu de Sant Jordi, per l'afany durant els
              75 anys d'història de promocionar el lleure, la cultura i el teatre entre els infants de Sabadell i de tot Catalunya. El setembre del mateix any
              2022, van ser els responsables d'oficiar el pregó de la Festa Major de Sabadell.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Historia
