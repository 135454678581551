import React from "react"

const Footer = () => {
  return (
    <nav className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue '>
      <div className='mx-6 flex justify-between items-center'>
        <a href='#home' className='text-white text-s font-mori'>
          VISITA'NS A LES XARXES:
        </a>
        <div className='hidden md:flex space-x-4 text-sm'>COPYRIGHT © 2023 JOVENTUT DE LA FARÀNDULA</div>
      </div>
    </nav>
  )
}

export default Footer
