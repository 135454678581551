import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { buildTitle, PageTitle } from "../components/PageTitle"

const Tta = () => {
  return (
    <div>
      <PageTitle title={buildTitle("TTA")} />
      <Navbar />
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-6xl lg:text-8xl tracking-wide font-bold text-blue font-brik px-6 uppercase'>TTA: Teatre per Adults</h1>
      </div>
      <div className='bg-gray border-r border-l border-blue py-4 px-0 text-blue px-6'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Calendari 2024-2025</h2>
            <p className='font-mori'>
              <strong>Prematrícula Antics Alumnes TTA i TTAmusical:</strong> Del dimarts 25 al divendres 28 de juny.
            </p>
            <p className='font-mori'>
              <strong>Noves Alumnes TTA i TTAmusical:</strong> Del dimarts 1 al divendres 4 de juliol.
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Què és el TTA?</h2>
            <p className='font-mori'>
              El TTA és la secció formativa de Joventut de la Faràndula que ofereix formació a partir dels 18 anys. El programa formatiu corre a càrrec de la
              companyia professional <strong>alGalliner</strong>.
            </p>
            <p className='font-mori'>Actualment, oferim dues línies diferenciades de formació per a adults:</p>
            <ul className='list-disc list-inside font-mori'>
              <li>
                <strong>Formació únicament teatral</strong>, enfocada al treball de text. Es realitza els dilluns de 20:00h a 21:30h o de 21:30h a 23:00h.
              </li>
              <li>
                <strong>Formació combinada de teatre, cant i dansa</strong>, enfocada al teatre musical. El grup dels dimarts de 20:45h a 22:15h fa un taller de
                teatre musical (interpretació, dansa i cant amb una mostra final) i el grup dels dijous de 20:45h a 22:15h treballa únicament tècnica vocal.
              </li>
            </ul>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Mostra final de curs</h2>
            <p className='font-mori'>
              Els grups de TTAtext i el grup de dimarts de TTAmusical fan una mostra de final de curs, on poden exhibir els seus treballs realitzats durant
              l'any.
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Activitat per a socis/es</h2>
            <p className='font-mori'>Aquesta activitat formativa està destinada exclusivament als socis i sòcies de la Joventut de la Faràndula.</p>
            <p className='font-mori'>
              Per a més informació, pots contactar a{" "}
              <a href='mailto:formacio@joventutdelafarandula.cat' className='underline text-blue'>
                formacio@joventutdelafarandula.cat
              </a>
              .
            </p>
          </div>

          <div className='border-blue py-4 px-6 flex items-center'>
            <img src='https://www.joventutdelafarandula.cat/img/alGalliner_logo.png' alt='Seu Social' className=' w-full lg:w-1/3 h-auto rounded' />
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Localització</h2>
            <p className='font-mori'>El curs es realitza a la seu social de la nostra entitat, situada al carrer República 47, Sabadell.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Tta
