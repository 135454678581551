import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { buildTitle, PageTitle } from "../components/PageTitle"

const AulaJove = () => {
  return (
    <div>
      <PageTitle title={buildTitle("Aula Jove")} />
      <Navbar />
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-6xl lg:text-8xl tracking-wide font-bold text-blue font-brik px-6 uppercase'>Aula Jove</h1>
      </div>
      <div className='bg-gray border-r border-l border-blue py-4 px-0 text-blue px-6'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Calendari 2024-2025</h2>
            <p className='font-mori'>
              <strong>Prematrícula Antics Alumnes Aula Jove + Alumnes TTI que passen a Aula Jove:</strong> del dimarts 11 al divendres 14 de juny.
            </p>
            <p className='font-mori'>
              <strong>Prematrícula Nous Alumnes Aula Jove:</strong> del dimarts 18 al divendres 22 de juny (es farà sorteig si no hi ha suficients places
              disponibles).
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Què és l’Aula Jove?</h2>
            <p className='font-mori'>
              L’Aula Jove és la secció formativa de Joventut de la Faràndula que ofereix formació a joves de 14 a 18 anys. El programa formatiu està a càrrec de
              la companyia professional <strong>Al Galliner</strong>. Els grups es divideixen en dues franges d’edat: <strong>JOVES I (14-16)</strong> i{" "}
              <strong>JOVES II (16-18)</strong>. Les sessions duren 2 hores i es realitzen a l’Estruch.
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Horaris de les sessions</h2>
            <ul className='list-disc list-inside font-mori'>
              <li>
                <strong>JOVES I:</strong> dimecres o dijous de 18:00h a 20:00h.
              </li>
              <li>
                <strong>JOVES II:</strong> dimecres o dijous de 20:00h a 22:00h.
              </li>
            </ul>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Programa formatiu JOVES I</h2>
            <ul className='list-disc list-inside font-mori'>
              <li>Espai/Grup (octubre)</li>
              <li>Cos/Moviment (novembre)</li>
              <li>Màscara neutra (desembre)</li>
              <li>Creació de personatge (gener)</li>
              <li>Coralitat (febrer)</li>
              <li>Obra final de curs creació col·lectiva (març-juny)</li>
            </ul>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Programa formatiu JOVES II</h2>
            <ul className='list-disc list-inside font-mori'>
              <li>Grup/Espai/Cos (octubre)</li>
              <li>Improvisació (novembre)</li>
              <li>Viewpoints (desembre)</li>
              <li>Treball d'escenes (gener)</li>
              <li>Veu i dicció (febrer)</li>
              <li>Obra final de curs teatre de text (març-juny)</li>
            </ul>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Activitats complementàries</h2>
            <p className='font-mori'>El curs es combinarà amb unes estades teatrals durant un cap de setmana, sortides al teatre i la representació final.</p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Beneficis de ser soci/sòcia</h2>
            <p className='font-mori'>
              Els alumnes de l'Aula Jove tenen la condició de soci/sòcia faranduler/a, la qual cosa els permet gaudir d'una invitació per a totes les
              representacions de Joventut de la Faràndula.
            </p>
            <p className='font-mori'>
              Per a més informació, pots contactar a{" "}
              <a href='mailto:formacio@joventutdelafarandula.cat' className='underline text-blue'>
                formacio@joventutdelafarandula.cat
              </a>
              .
            </p>
          </div>

          <div className='border-blue py-4 px-6 flex items-center'>
            <img src='https://www.joventutdelafarandula.cat/img/alGalliner_logo.png' alt='Seu Social' className=' w-full lg:w-1/3 h-auto rounded' />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AulaJove
