import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Newsletter from "../components/Newsletter"

import { ReactComponent as Cotxe } from "../svg/cotxe.svg"
import { ReactComponent as Tren } from "../svg/tren.svg"
import { ReactComponent as Bus } from "../svg/bus.svg"
import { buildTitle, PageTitle } from "../components/PageTitle"

const ComArribar = () => {
  return (
    <div>
      <PageTitle title={buildTitle("Com arribar")} />
      <Navbar />
      <div className='bg-gray border-r border-l border-blue py-4 px-0 text-blue px-6'>
        <h1 className='text-6xl lg:text-8xl tracking-wide font-bold text-blue font-brik px-6 uppercase'>Com arribar</h1>
      </div>
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue px-6'>
        <div className='flex flex-col py-6'>
          <iframe
            className='rounded'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.585853298198!2d2.1084337!3d41.5460189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49506b4e712db%3A0xb03767339f9a990b!2sTeatro%20La%20Far%C3%A1ndula!5e0!3m2!1ses!2ses!4v1686148370000!5m2!1ses!2ses'
            width='100%'
            height='450'
            frameBorder='0'
            style={{ border: 0 }}
            allowFullScreen=''
            aria-hidden='false'
            tabIndex='0'
          ></iframe>
          <p className='mt-4 text-xl font-mori uppercase'>
            <a href='https://goo.gl/maps/TeatreLaFarandula' className='underline'>
              Carrer de la República, 33 <br />
              08202 Sabadell, Barcelona
            </a>
          </p>
        </div>
        <div className='flex flex-col lg:flex-row justify-around py-6'>
          <div className='flex flex-col items-center'>
            <Cotxe className='w-12 h-12 mb-2' />
            <h3 className='text-xl font-bold text-blue uppercase'>Cotxe</h3>
            <p className='text-center font-mori'>Per l'autopista C-58: sortida 13, SABADELL Centre</p>
          </div>
          <div className='flex flex-col items-center'>
            <Tren className='w-12 h-12 mb-2' />
            <h3 className='text-xl font-bold text-blue uppercase'>Tren</h3>
            <p className='text-center font-mori'>Rodalies de Catalunya: Línia C4 Terrassa - Manresa Estació: Sabadell-Centre</p>
            <p className='text-center font-mori'>F.G.C. – Linia S2 (direcció Sabadell): estació: Sabadell-Plaça Major</p>
          </div>
          <div className='flex flex-col items-center'>
            <Bus className='w-12 h-12 mb-2' />
            <h3 className='text-xl font-bold text-blue uppercase'>Autobús</h3>
            <p className='text-center font-mori'>Estació d'autobusos de la Plaça d'Antoni Llonch</p>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </div>
  )
}

export default ComArribar
