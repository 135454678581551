import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { buildTitle, PageTitle } from "../components/PageTitle"

const TTi = () => {
  return (
    <div>
      <PageTitle title={buildTitle("TTi")} />
      <Navbar />
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-6xl lg:text-8xl tracking-wide font-bold text-blue font-brik px-6 uppercase'>TTi: Taller de Teatre Infantil</h1>
      </div>
      <div className='bg-gray border-r border-l border-blue py-4 px-0 text-blue px-6'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Què és el TTi?</h2>
            <p className='font-mori'>
              El TTi, Taller de Teatre Infantil, és la extraescolar de teatre que Joventut de la Faràndula ofereix a nens/es de 6 a 14 anys (1r de primària - 2n
              d'ESO). Els alumnes es divideixen en grups segons la franja d'edat que tenen: Petits (1r i 2n de Primària), Mitjans Petits (3r i 4t de Primària),
              Mitjans Grans (5è i 6è de Primària) i Grans (1r i 2n d'ESO).
            </p>
            <p className='font-mori'>
              El curs es divideix en dos blocs. El primer, d’octubre a gener, es centra en exercicis teatrals, de veu, cos, jocs i tallers que ajuden a l'infant
              a conèixer el món del teatre i a crear cohesió entre els companys. El segon bloc, de febrer a maig, es dedica a la preparació d’una obra que es
              presenta a la "Mostra de Cloenda" al juny.
            </p>
          </div>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Activitats complementàries</h2>
            <p className='font-mori'>
              A més dels exercicis teatrals regulars, es fan unes estades de 3 dies i 2 nits durant el Pont de la Salut, on els alumnes de tots els grups es
              barregen per conèixer altres companys i monitors. Durant aquestes estades, a més d'exercicis teatrals, gaudeixen de dos espectacles d'altres
              companyies de teatre.
            </p>
          </div>

          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Importància educativa del TTi</h2>
            <p className='font-mori'>
              El TTi és una eina educativa molt important. Ajuda als nens/es a comunicar-se no només amb el públic, sinó també amb els seus companys i monitors.
              També els ensenya a ser més responsables i a treballar en equip per assolir un objectiu comú: l'obra de final de curs.
            </p>
            <p className='font-mori'>
              Els últims anys, el TTi ha comptat amb gairebé 150 alumnes repartits en deu grups. Totes les sessions es realitzen a Ca l'Estruch, carrer Sant
              Isidre, 140, Sabadell.
            </p>
          </div>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>Preinscripcions i matrícula</h2>
            <p className='font-mori'>
              Per a les famílies interessades en que els seus fills i filles participin al TTi, el funcionament és el següent:
              <ul className='list-disc list-inside'>
                <li>La segona setmana de juny, després de les prematrícules dels alumnes actuals, s'obre el procés de preinscripció per als nous alumnes.</li>
                <li>S’adjudica un número a cada alumne per participar al sorteig de places disponibles.</li>
                <li>Si l'alumne obté una plaça, al setembre es formalitza la matrícula oficial.</li>
              </ul>
            </p>
            <p className='font-mori'>
              A més, tots els alumnes del TTi gaudeixen d'un descompte del 50% per a totes les obres (no representacions) de temporada estable de Joventut de la
              Faràndula.
            </p>
          </div>
          <div className='border-blue py-4 px-6'>
            <h2 className='text-3xl tracking-wide font-bold text-blue font-brik pb-4 uppercase'>CALENDARI 2024-2025</h2>
            <p className='font-mori'>
              {" "}
              PREMATRÍCULA ANTINCS ALUMNES TTI:
              <ul className='list-disc list-inside'>
                <li>Del dimarts 4 al divendres 7 de juny</li>
              </ul>
            </p>
            <p className='font-mori'>
              {" "}
              NOUS ALUMNES TTI (SORTEIG)
              <ul className='list-disc list-inside'>
                <li>Del dimarts 11 al divendres 14 de juny</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TTi
