import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Newsletter from "../components/Newsletter"
import { buildTitle, PageTitle } from "../components/PageTitle"

const ApostesEnEscena = () => {
  return (
    <div>
      <PageTitle title={buildTitle("Contacte")} />
      <Navbar />
      {/* Título de la página */}
      <div className='bg-gray border-r border-b border-l border-blue py-4 px-0 text-blue'>
        <h1 className='text-6xl lg:text-8xl tracking-wide font-bold text-blue font-brik px-6'>Apostes En Escena</h1>
      </div>
      <Newsletter />
      <Footer />
    </div>
  )
}

export default ApostesEnEscena
