import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Navbar from "../components/Navbar"
import FitxaArtistica from "../components/FitxaArtistica"
import Gallery from "../components/Gallery"
import Newsletter from "../components/Newsletter"
import Footer from "../components/Footer"

const ProjectDetail = () => {
  const [project, setProject] = useState(null)

  const{slug}=useParams()


 useEffect(() => {
    fetch(process.env.REACT_APP_JF_API_HOST + "/espectacle/" + slug)
      .then((response) => response.json())
      .then((json) => {
        setProject(json)
      }
      )
      .catch((error) => console.error(error))
  }, [slug])

  console.log(project)


  if (!project) {
    return <div>Aquest espectacle no existeix</div>
  }

  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Título separado */}
      <div className='border-b border-l border-r border-blu'>
        <h1 className='p-6 lg:p-6  border-blu text-5xl lg:text-6xl font-brik text-blue text-center lg:text-start'>{project.title}</h1>
      </div>

      {/* Contenedor para imagen e información clave */}
      <div className='flex lg:flex-row flex-col border-l border-r border-blu lg:h-auto'>
        {/* Imagen del espectáculo */}
        <div className='lg:w-2/3 w-full lg:h-auto'>
          <img
            src={project.imageUrl}
            alt={project.title}
            className='w-full h-full object-cover'
            style={{ height: "100%", maxHeight: "520px" }} // La imagen se adapta al contenedor
          />
        </div>

        {/* Información clave a la derecha */}
        <div className='tracking-normal text-gray text-xl lg:w-1/3 w-full bg-blue text-white p-8 flex flex-col justify-between lg:h-auto'>
          {/* Ficha de información organizada en grid */}
          <div className='grid grid-cols-2 gap-y-6 gap-x-6'>
            {/* Hora y Lugar */}
            <div className='col-span-2'>
              <h2 className='text-3xl font-brik uppercase'>HORA I LLOC</h2>
              <p className='text-base'>
                {project.date}
              </p>
              {/* <p className='text-base'>{project.location.text}</p> */}
            </div>

            {/* AUTORIA y MÚSICA en la misma fila */}
            <div>
              <h2 className='text-2xl font-brik'>AUTORIA</h2>
              <p className='text-base'>{project.author}</p>
            </div>
            <div>
              <h2 className='text-2xl font-brik'>MÚSICA</h2>
              <p className='text-base'>{project.music}</p>
            </div>

            {/* DIRECCIÓ y COREO en la misma fila */}
            <div>
              <h2 className='text-2xl font-brik'>DIRECCIÓ</h2>
              <p className='text-base'>{project.direction}</p>
            </div>
            <div>
              <h2 className='text-2xl font-brik'>COREO</h2>
              <p className='text-base'>{project.coreo}</p>
            </div>

            {/* DURADA y IDIOMA en la misma fila */}
            <div>
              <h2 className='text-2xl font-brik'>DURADA</h2>
              <p className='text-base'>{project.duration}</p>
            </div>
            <div>
              <h2 className='text-2xl font-brik'>IDIOMA</h2>
              <p className='text-base'>{project.language}</p>
            </div>

            {/* EDAT en una fila independiente */}
            <div>
              <h2 className='text-2xl font-brik'>EDAT</h2>
              <p className='text-base'>{project.age}</p>
            </div>
            <div>
              <h2 className='text-2xl font-brik'>Temporada</h2>
              <p className='text-base'>{project.season}</p>
            </div>
          </div>

          {/* Botón */}
          <button className='mt-8 w-full py-2 bg-gray text-blue font-mori shadow-md rounded-full'>COMPRA D'ENTRADES</button>
        {/* URL DE TICKETS  */}
        </div>
      </div>
      {/* Text sota imatge */}
      <div className='w-full border-l border-r border-b border-blu lg:flex flex-row'>
        <div className='w-full lg:w-2/3 py-6 px-8 text-blue text-xl tracking-normal'>
        <div
          className="html-content"
          dangerouslySetInnerHTML={{ __html: project.description }} // Renderiza HTML directamente
        />
        </div>
        <div className='w-full lg:w-1/3 py-6 px-8 text-blue text-xl tracking-normal'>
          <p className='mb-4'> preus</p>
        </div>
      </div>

      <FitxaArtistica casting={project.casting} />
      <Gallery images={project.gallery} />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default ProjectDetail
